.backdrop{
    position: absolute;
    background-color: #fafafa;
}
.NavbarItems:hover{
    color: #E8E8E8;
    text-decoration: none;
}

.NavbarItems{
    color: #B8B8B8;
    margin: 5px;
    text-decoration: none;
    font-weight: lighter;
    font-weight: 400; 
}

.NavbarItemsWithPadding:hover{
  color: #E8E8E8;
  text-decoration: none;
}
.NavbarItemsWithPadding{
  padding-top:1.5314em;
  padding-bottom: 1.5314em;
  color: #B8B8B8;
  margin: 5px;
  text-decoration: none;
  font-weight: lighter;
  font-weight: 400; 
}

.noBox:visited, .noBox:active, .noBox:focus, .noBox:hover{
 outline:0 !important
}

.activeTab{
  color: white;
}

.NavbarBrand, .NavbarBrand:hover{
    color: white;
    margin: 5px;
}  

.NavBar{
    z-index:20;
    width: 100%;
    position: fixed;
    top: 0em;
    display: block;
    background-color: #353942;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}



html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;

  /* Give app full page to work with */
  min-height:100vh;
  min-width: 100vw;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

#page-wrap {
  text-align: center;

  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  color: #B8B8B8;
  transition: color 0.2s;
  font-weight: 400;
  margin-top: .75em;
  margin-bottom: .75em;
}

/* Change color on hover */
.bm-item:hover {
  color: #E8E8E8;
}

.bm-item:active {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 19px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em;
  font-size: 1.15em;
  overflow-y: hidden !important; 
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-burger-bars{
  background-color: #CCCCCC;
}
.bm-burger-button:hover .bm-burger-bars {
  background-color: white;
  color: white;
}

.webHelloH1{
    font-weight: 300;
    text-align: center;
}

.webBuildH2{
    font-weight: 200;
    text-align: center;
}

.align_and_space{
    text-align: center;
    margin-top: 1.5em;
}

.webGeneral{
    font-weight: 200;
    padding-left: 1em;
    padding-right: 1em;
}

.webName{
    font-weight: 300;
    text-align: center;
    margin-bottom: -.005em;
}
.myList{
list-style-type: none;
margin-left: 3vw;
margin-right:3vw;
padding:0em;
text-align: left;
margin-bottom: 1em;
}

.myListWithBullets{
    list-style-type: disc;
    margin-left: 3vw;
    margin-right:3vw;
    padding:0em;
    text-align: left;
    }
    
