
.webHelloH1{
    font-weight: 300;
    text-align: center;
}

.webBuildH2{
    font-weight: 200;
    text-align: center;
}

.align_and_space{
    text-align: center;
    margin-top: 1.5em;
}

.webGeneral{
    font-weight: 200;
    padding-left: 1em;
    padding-right: 1em;
}

.webName{
    font-weight: 300;
    text-align: center;
    margin-bottom: -.005em;
}